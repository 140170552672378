import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import React from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import {ToastContainer} from "react-toastify";
import {Container} from 'reactstrap';
// routes config
import routes from '../../routes';
import LoginRequiredContainer from "../LoginRequiredContainer";
import featureFlags from './../../featureFlags'
import DefaultAside from './DefaultAside';
import DefaultHeader from './DefaultHeader';
import {
  SidebarTitle,
  SidebarLink,
  SidebarSeparator,
  SidebarExternalLink
} from '../../ui-kit/src/sidebar/SidebarComponents'

function DefaultLayout() {
  const location = useLocation();

  return (<LoginRequiredContainer>
      <div className="app">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          closeButton={false}
        />
        <AppHeader fixed>
          <DefaultHeader/>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader/>
            <AppSidebarForm/>
            <AppSidebarNav>
              <SidebarTitle title={"DATA"}/>
              <SidebarLink link={'/marketing'} icon={'fa-thin fa-megaphone'} title={'Marketing'} iconFontSize={'0.813rem'}
                           location={location}/>
              {featureFlags.ordersDisplayOrdersPageNavigation &&
                <SidebarLink link={'/orders'} icon={'fa-thin fa-clipboard-list'} title={'Orders'} location={location}/>}
              <SidebarTitle title={"CONNECTIONS"}/>
              <SidebarSeparator/>
              <SidebarLink link={'/output'} icon={'fa-thin fa-bolt-lightning'} title={'Outputs'} location={location}/>
              <SidebarLink link={'/brands'} icon={'fa-thin fa-industry'} title={'Brands'} iconFontSize={'0.875rem'}
                           location={location}/>
              {/*<SidebarLink link={'/inputs'} icon={'fa-solid fa-right-to-bracket'} title={'Input'} location={location}/>*/}
              <SidebarTitle title={"HELP"} />
              <SidebarSeparator />
              <SidebarExternalLink title={'Help Centre'} icon={'fa-thin fa-life-ring'} link={'https://intercom.help/vuuh/en/'}
                                   iconFontSize={'0.875rem'} dataTestId={"sidebar-link-help-centre"}/>
            </AppSidebarNav>

            <AppSidebarFooter/>
            <AppSidebarMinimizer/>
          </AppSidebar>
          <main className="main">
            <Container style={{padding: '2rem', height: '100%'}} fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (
                        <Route key={idx} path={route.path} exact={route.exact}
                               name={route.name} render={props => (
                          <route.component {...props} />
                        )}/>)
                      : (null);
                  },
                )}
                <Redirect from="/" to="/orders"/>
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside/>
          </AppAside>
        </div>
        {/*<AppFooter>*/}
        {/*  <DefaultFooter/>*/}
        {/*</AppFooter>*/}
      </div>
    </LoginRequiredContainer>
  );
}

export default DefaultLayout;
