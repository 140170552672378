import React, {useEffect, useState} from 'react';
import {withRouter, useHistory} from "react-router-dom";
import bluredNavbar from "../../../assets/svg/navigation.svg";
import CreatingAccountModal, {PreFilledFieldsType} from "../../../ui-kit/src/specialized/userForms/creatingAccount/CreatingAccountModal";
import LoginMutation from "../../../mutations/LoginMutation";
import environment, {setAuthToken} from "../../../Environment";
import CreateRetailerUserMutation from "../../../mutations/CreateRetailerUserMutation";
import {initializeAnonymousInUser} from "../../../common/intercomIntegration";
import {QueryRenderer, graphql} from "react-relay";
import {PayloadError} from "relay-runtime";
import {UserRegistrationDataType} from "../../../ui-kit/src/specialized/userForms/creatingAccount/types"
import {RegisterQuery} from "./__generated__/RegisterQuery.graphql";

const query = graphql`
    query RegisterQuery ($token: String!) {
        getRetailerByClaimToken(token: $token) {
            errors {
                code
                message
            }
            retailer {
                id
                name
                vat
            }
            emailAddress
            phoneNumber
            zipCode
            city
            address
        }
    }
`

type ClaimAccountPreFillProps = {
  claimTokenParam: string | null,
  setClaimErrors: (val: string | null) => void,
  preFilledFields: PreFilledFieldsType,
  setPreFilledFields: (val: PreFilledFieldsType) => void,
  children: JSX.Element
}

function ClaimAccountPreFill({claimTokenParam, setClaimErrors, preFilledFields, setPreFilledFields, children}: ClaimAccountPreFillProps) {
  if(claimTokenParam && !preFilledFields) {
    return <QueryRenderer<RegisterQuery>
      environment={environment}
      query={query}
      variables={{token: claimTokenParam}}
      render={({error, props}) => {
        if(error) {
          setClaimErrors(error.message);
        } else if(props) {
          const retailerInfo = props?.getRetailerByClaimToken;
          if(retailerInfo?.errors && retailerInfo.errors.length > 0) {
            if(retailerInfo.errors[0]?.code === "token_not_found"){
              window.location.href = "https://retailers.vuuh.com"
            }
            setClaimErrors(retailerInfo?.errors[0]?.message || null);
          }
          setPreFilledFields({
            vatCvr: retailerInfo?.retailer?.vat ? retailerInfo?.retailer?.vat : null,
            companyName: retailerInfo?.retailer?.name ? retailerInfo?.retailer?.name : null,
            address: retailerInfo?.address ? retailerInfo?.address : null,
            city: retailerInfo?.city ? retailerInfo?.city : null,
            postNumber: retailerInfo?.zipCode ? retailerInfo?.zipCode : null,
            email: retailerInfo?.emailAddress ? retailerInfo?.emailAddress : null,
            phoneNumber: retailerInfo?.phoneNumber ? retailerInfo?.phoneNumber : null,
          })
        }
        return children;
      }}/>
  } else {
    return children;
  }
}

function Register() {
  const claimTokenParam = new URLSearchParams(window.location.search).get('claim');
  const [errors, setErrors] = useState<readonly PayloadError[] | Error>([])
  const [claimErrors, setClaimErrors] = useState<string | null>(null);
  const [preFilledFields, setPreFilledFields] = useState<PreFilledFieldsType>(null);
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  useEffect(() => {
    initializeAnonymousInUser();
  }, [])

  const onSubmit = (data: UserRegistrationDataType,  onSuccessCallback: () => void) => {
    setIsLoading(true)
    CreateRetailerUserMutation(
      {
        firstName: data.name,
        companyName: data.companyName,
        email: data.email,
        password: data.password,
        repeatPassword: data.repeatPassword,
        vatCvr: data.vatCvr,
        address: data.address,
        city: data.city,
        postNumber: data.postNumber,
        checked: data.checked,
        phoneNumber: data.phoneNumber,
        registerToken: data.registerToken
      },
      environment,
      (email, password) => {
        onSuccessCallback();
        onSuccess(email, password);
      },
      onError)
  }

  const onSuccess = (email: string, password: string) => {
    setIsLoading(false)
    LoginMutation(
      email, password,
      (response) => {
        setAuthToken(response.tokenAuth.token);
      },
      (errors) => {
        onError(errors)
      })
  }

  const onError = (errors: readonly PayloadError[] | Error) => {
    setErrors(errors)
    setIsLoading(false)
  }

  const toggle = () => {
    if(claimTokenParam !== null) {
      history.push("/brands?product_tour_id=411912");
    } else {
      history.push("/orders");
    }
  }

    return <ClaimAccountPreFill
      claimTokenParam={claimTokenParam}
      setClaimErrors={setClaimErrors}
      preFilledFields={preFilledFields}
      setPreFilledFields={setPreFilledFields}>
      <div className="app flex-row align-items-center">
        <img src={bluredNavbar} alt={"Blurred nav-bar"} className={"blured-navbar"}/>
        <CreatingAccountModal isOpen={true}
                              toggle={toggle}
                              loading={isLoading}
                              errors={errors}
                              claimErrors={claimErrors}
                              scope={'retailers'}
                              onCancel={() => window.location.href = "https://vuuh.com"}
                              onSubmit={onSubmit}
                              preFilledFields={preFilledFields}
        />
      </div>
    </ClaimAccountPreFill>
}

export default withRouter(Register);
