import React from "react";
import Dropdown, {Option, SelectableOption} from "../../../../../atoms/Dropdown";
import {isJsonSchemaType} from "../../../../../stringUtils";
import {RuleIcon} from "../icons/RuleIcon";
import RULES from "../rules";
import Tooltip from "../../../../../atoms/Tooltip";
import {GeneralTokenRule, Location, ProductPathTokenType, TokenRuleException} from "../../types";
import Button from "../../../../../atoms/Button";

const ADD_BRAND_RULE = {
  value: 'BRAND_RULE',
  label: 'Add Brand Specific Rules',
  icon: <i className="fa-light fa-fw fa-industry"/>,
  extraParams: {}
}

function isBrandRule(op: SelectableOption) {
  return op.value === 'BRAND_RULE';
}

function RuleDropdownIcon({name, src}: { name: string, src: string }) {
  return <span><RuleIcon src={src}/> <span className={'ml-2'}>{name}</span></span>
}

type GetOptionsParamsType = {
  allowTextCaseRules: boolean,
  allowNumberRules: boolean,
  allowCustomizeValues: boolean,
  presentRuleTypes: string[],
}

function getOptions({
                      allowTextCaseRules,
                      allowNumberRules = false,
                      allowCustomizeValues = false,
                      presentRuleTypes
                    }: GetOptionsParamsType) {
  let options: (Option & { extraParams?: Omit<GeneralTokenRule, "type"> })[] = [
    {
      value: RULES.CONDITIONAL_RULE.id,
      label: 'Set conditional rules',
      icon: RULES.CONDITIONAL_RULE.icon,
      extraParams: {
        kind: 'and',
        conditions: [{match_on_token: null, path: '', value: [], op: 'eq'}],
        replacements: []
      }
    },
    {
      divider: true
    },
    {
      value: RULES.CUSTOM_TEXT_RULE.id,
      disabled: presentRuleTypes.includes(RULES.CUSTOM_TEXT_RULE.id),
      label: 'Add custom text',
      icon: RULES.CUSTOM_TEXT_RULE.icon,
      extraParams: {before: '', after: '', ignore_if_none: false},
    },
    {
      value: RULES.FIRST_AVAILABLE_RULE.id,
      disabled: presentRuleTypes.includes(RULES.FIRST_AVAILABLE_RULE.id),
      label: 'Modify empty values',
      icon: RULES.FIRST_AVAILABLE_RULE.icon,
      extraParams: {default_value: null, paths: []},
    },
    {
      value: RULES.REDUCE_CONTENT_RULE.id,
      disabled: presentRuleTypes.includes(RULES.REDUCE_CONTENT_RULE.id),
      label: 'Reduce content',
      icon: RULES.REDUCE_CONTENT_RULE.icon,
      extraParams: {length: 5, mode: 'chars', strategy: "end"},
    },
    {
      value: RULES.REMOVE_CHARACTERS_RULE.id,
      label: 'Remove characters',
      icon: RULES.REMOVE_CHARACTERS_RULE.icon,
      extraParams: {pattern: '', where: Location.ALL},
    },
    {
      value: RULES.REMOVE_SECTION_RULE.id,
      label: 'Remove content',
      icon: RULES.REMOVE_SECTION_RULE.icon,
      extraParams: {section_path: null},
    },
    {
      value: RULES.TRANSLATE_TEXT_RULE.id,
      label: "Translate text",
      icon: RULES.TRANSLATE_TEXT_RULE.icon,
      extraParams: {translate_to_language: null},
      disabled: presentRuleTypes.includes(RULES.TRANSLATE_TEXT_RULE.id)
    },
    {
      value: RULES.DATE_DELTA_RULE.id,
      label: 'Date delta',
      icon: RULES.DATE_DELTA_RULE.icon,
      extraParams: {date_output_format: "%Y-%m-%d", days: 1},
    },
    {
      value: RULES.ROUND_PERIOD_RULE.id,
      label: 'Round date',
      icon: RULES.ROUND_PERIOD_RULE.icon,
      extraParams: {date_output_format: "%Y-%m-%d", next_month_day: 1},
    },
    {
      value: RULES.REPLACE_CHARACTERS_RULE.id,
      label: 'Replace characters',
      icon: RULES.REPLACE_CHARACTERS_RULE.icon,
      extraParams: {
        keep_text_case: false,
        replace_target: null,
        replace_with: null,
        where: Location.ALL
      },
    }
  ]
  if (allowTextCaseRules) {
    options.push({
      value: 'TextCaseRule',
      disabled: presentRuleTypes.includes(RULES.TEXT_CASE_RULE.id),
      label: 'Specify Text Case',
      icon: RULES.TEXT_CASE_RULE.icon,
      extraParams: {transform: null},
    })
  }
  if (allowNumberRules) {
    options.push({
        divider: true
      },
      {
        value: RULES.NUMERIC_FORMAT_RULE.id,
        disabled: presentRuleTypes.includes(RULES.NUMERIC_FORMAT_RULE.id),
        label: <RuleDropdownIcon name={'Change numeric format'} src={RULES.NUMERIC_FORMAT_RULE.icon}/>,
        extraParams: {numeric_format: null},
      })
  }
  if (allowCustomizeValues) {
    options.push({
      value: RULES.CUSTOMIZE_VALUES_RULE.id,
      disabled: presentRuleTypes.includes(RULES.CUSTOMIZE_VALUES_RULE.id),
      label: 'Customize Value',
      icon: RULES.REPLACE_CHARACTERS_RULE.icon,
      extraParams: {values: [{from: "", to: ""}]},
    })
  }
  return options
}

type AddRuleDropdownProps = {
  onChange: (val: ProductPathTokenType & { exceptions?: TokenRuleException[] }) => void,
  token: ProductPathTokenType,
  tooltip: string,
  allowBrandRules: boolean,
  allowCustomizeValues?: boolean,
  allowTextCaseRules?: boolean,
  tokenValueType?: string[] | null,
}

export default function AddRuleDropdown({
                                          onChange, token, tooltip,
                                          tokenValueType,
                                          allowBrandRules,
                                          allowCustomizeValues = false,
                                          allowTextCaseRules = true
                                        }: AddRuleDropdownProps) {
  let isCustomizeValuesFlagEnabled = true;

  let options = getOptions({
    presentRuleTypes: token.rules?.map(r => r.type) || [],
    allowTextCaseRules,
    allowNumberRules: tokenValueType ? isJsonSchemaType({type: tokenValueType}, 'number') : false,
    allowCustomizeValues: allowCustomizeValues && isCustomizeValuesFlagEnabled
  });

  if (allowBrandRules) {
    options.unshift(ADD_BRAND_RULE, {divider: true});
  }

  return <>
    <Dropdown
      caret={false}
      isSearchable={false}
      currentValue={null}
      ToggleTag={(props) => <Tooltip text={tooltip}
                                     placement={'right'}>
        <Button onClick={props.toggleDropdowns}>
          <i className="fa-regular fa-plus m-0"/>
        </Button>
      </Tooltip>}
      onSelectOption={(chosenRule: (SelectableOption & { extraParams?: Omit<GeneralTokenRule, "type"> })) => {
        // theoretically, isBrandRule should always return false when allowBrandRule is false
        // because the ADD_BRAND_RULE is not included in the options
        if (isBrandRule(chosenRule) && allowBrandRules) {
          onChange({
            ...token,
            brandRules: [{brand: null, rules: [], exceptions: []}, ...(token.brandRules || [])]
          })
        } else {
          const chosenRuleToInsert = {type: chosenRule.value, ...chosenRule.extraParams} as GeneralTokenRule;
          const otherTokenRules = (token.rules || []);

          if (chosenRule.value === RULES.FIRST_AVAILABLE_RULE.id || chosenRule.value === RULES.CUSTOM_TEXT_RULE.id) {
            // for the FIRST_AVAILABLE_RULE and CUSTOM_TEXT_RULE, insert it to the front
            onChange({
              ...token,
              rules: [chosenRuleToInsert, ...otherTokenRules]
            })
            return;
          }
          onChange({
            ...token,
            rules: [...otherTokenRules, chosenRuleToInsert]
          });
        }
      }}
      options={options}
    />
  </>;
}
