import React from 'react';
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import Dropdown, {SelectableOption} from "../../../../../../../atoms/Dropdown";
import styles from "./TranslateTextRule.module.scss";

const checkedRadioButton = <i className={`fa-solid fa-circle-dot ${styles.checkedRadioButton}`} />;
const uncheckedRadioButton = <i className={`fa-thin fa-circle ${styles.uncheckedRadioButton}`} />;

type TranslateTextRuleType = {
  translate_to_language: string | null,
  onChange: (val: {translate_to_language: string}) => void,
  onRemove: () => void,
}

export default function TranslateTextRule({translate_to_language, onChange, onRemove}: TranslateTextRuleType) {
  const languageOptions: SelectableOption[] = [
    {
      label: "Danish",
      value: "danish",
      icon: translate_to_language === "danish" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "danish" && styles.selectedOption
    },
    {
      label: "Dutch",
      value: "dutch",
      icon: translate_to_language === "dutch" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "dutch" && styles.selectedOption
    },
    {
      label: "English",
      value: "english",
      icon: translate_to_language === "english" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "english" && styles.selectedOption
    },
    {
      label: "Finnish",
      value: "finnish",
      icon: translate_to_language === "finnish" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "finnish" && styles.selectedOption
    },
    {
      label: "French",
      value: "french",
      icon: translate_to_language === "french" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "french" && styles.selectedOption
    },
    {
      label: "German",
      value: "german",
      icon: translate_to_language === "german" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "german" && styles.selectedOption
    },
    {
      label: "Greek",
      value: "greek",
      icon: translate_to_language === "greek" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "greek" && styles.selectedOption
    },
    {
      label: "Italian",
      value: "italian",
      icon: translate_to_language === "italian" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "italian" && styles.selectedOption
    },
    {
      label: "Norwegian",
      value: "norwegian",
      icon: translate_to_language === "norwegian" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "norwegian" && styles.selectedOption
    },
    {
      label: "Polish",
      value: "polish",
      icon: translate_to_language === "polish" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "polish" && styles.selectedOption
    },
    {
      label: "Portuguese",
      value: "portuguese",
      icon: translate_to_language === "portuguese" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "portuguese" && styles.selectedOption
    },
    {
      label: "Romanian",
      value: "romanian",
      icon: translate_to_language === "romanian" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "romanian" && styles.selectedOption
    },
    {
      label: "Spanish",
      value: "spanish",
      icon: translate_to_language === "spanish" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "spanish" && styles.selectedOption
    },
    {
      label: "Swedish",
      value: "swedish",
      icon: translate_to_language === "swedish" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "swedish" && styles.selectedOption
    },
    {
      label: "Turkish",
      value: "turkish",
      icon: translate_to_language === "turkish" ? checkedRadioButton : uncheckedRadioButton,
      className: translate_to_language === "turkish" && styles.selectedOption
    },
  ];
  const selectedLanguageOption: SelectableOption | undefined = languageOptions.find(op => op.value === translate_to_language)

  return <div className={styles.container}>
    <div className={styles.iconContainer}>
      <i className={`fa-regular fa-language ${styles.icon}`}/>
    </div>
    <span className={styles.lightText}>Translate this data point to</span>
    <Dropdown currentValue={selectedLanguageOption || {label: "Select language"}}
              options={languageOptions}
              onSelectOption={op => {
                op.value && onChange({translate_to_language: op.value});
              }}
              className={styles.languageSelector}
              optionClassName={'d-flex align-items-center'}/>
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
