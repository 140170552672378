/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateRetailerStoreMutationInput = {
    id: string;
    name?: string | null | undefined;
    address?: string | null | undefined;
    zipCode?: string | null | undefined;
    city?: string | null | undefined;
    gln?: string | null | undefined;
    aliases?: Array<string> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type EditRetailerStoreMutationVariables = {
    input: UpdateRetailerStoreMutationInput;
};
export type EditRetailerStoreMutationResponse = {
    readonly updateRetailerStore: {
        readonly instance: {
            readonly retailer: {
                readonly id: string;
                readonly name: string;
                readonly stores: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                        } | null;
                    } | null>;
                };
            };
            readonly name: string;
            readonly zipCode: string | null;
            readonly city: string | null;
            readonly address: string | null;
            readonly id: string;
        };
    } | null;
};
export type EditRetailerStoreMutation = {
    readonly response: EditRetailerStoreMutationResponse;
    readonly variables: EditRetailerStoreMutationVariables;
};



/*
mutation EditRetailerStoreMutation(
  $input: UpdateRetailerStoreMutationInput!
) {
  updateRetailerStore(input: $input) {
    instance {
      retailer {
        id
        name
        stores {
          edges {
            node {
              id
            }
          }
        }
      }
      name
      zipCode
      city
      address
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateRetailerStoreMutationPayload",
    "kind": "LinkedField",
    "name": "updateRetailerStore",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RetailerStoreNode",
        "kind": "LinkedField",
        "name": "instance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerNode",
            "kind": "LinkedField",
            "name": "retailer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerStoreNodeConnection",
                "kind": "LinkedField",
                "name": "stores",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RetailerStoreNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RetailerStoreNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zipCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditRetailerStoreMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditRetailerStoreMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "779530636bdc2d048ab1144139499cc6",
    "id": null,
    "metadata": {},
    "name": "EditRetailerStoreMutation",
    "operationKind": "mutation",
    "text": "mutation EditRetailerStoreMutation(\n  $input: UpdateRetailerStoreMutationInput!\n) {\n  updateRetailerStore(input: $input) {\n    instance {\n      retailer {\n        id\n        name\n        stores {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n      name\n      zipCode\n      city\n      address\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4aaba02e587d70345104323d0e65233b';
export default node;
